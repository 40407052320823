<template>
  <div>
    <div class="search_container add_button">
      <div>
        <span>应用名称：</span>
        <SelectScroll
          @getSelectId="getSelectId($event)"
          :config="selectConfig"
          v-model="createUser"
        ></SelectScroll>
      </div>
      <div class="blank_contain" style="text-align: right">
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div>
    <div style="margin-top: 20px">
      <a-table
        :loading="loading"
        style="min-width: 1500px"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="dataTable"
          :scroll="{ y: 379 }"
      >
        <span slot="action" slot-scope="text, record">
          <a style="margin-left: 20px" @click="openModel('look', record)">
            查看密码
          </a>
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import { sassLogicalResourceApi } from "@/api/sassLogicalResource.js";
import SelectScroll from "../../components/selectScroll.vue";
export default {
  mixins: [pagination],
  components: {
    ModelTag,
    SelectScroll,
  },
  data() {
    return {
      selectConfig: {
        url: "/cloud-platform-backend-facade/front-api/v1/tenant/base/appNameByPage",
        params: "appName",
        label: "appName",
        value: "appName",
        width: "240px",
        pass: true,
        password: "",
      },
      createUser: null,
      loading: false,
      resourceType: "",
      appName: "",
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "",
        width: "60%",
        okText: "保存",
        delArr: [],
        password: "",
      },

      columns: [
        {
          title: "应用名称",
          dataIndex: "appName",
          key: "appName",
          align: "center",
        },

        {
          title: "地址",
          dataIndex: "resourceAddress",
          key: "resourceAddress ",
          align: "center",
        },
        {
          title: "端口",
          dataIndex: "resourcePort",
          key: "resourcePort",
          align: "center",
        },
        {
          title: "用户名",
          dataIndex: "username",
          key: "username",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
        getSelectId(val) {
      this.createUser = val;
    },
    //重置
    reset() {
      this.createUser = null;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    //查询数据接口
    getData() {
      this.loading = true;
      let params = {
        appName: this.createUser,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      console.log("creater",this.createUser)
      sassLogicalResourceApi.getTableData(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询
    handleSearch() {
      this.pagination.current = 1;
      this.getData();
    },

    //调用弹框事件
    async openModel(type, record) {
      this.modelConfig.id = record ? record.id : null;
      this.modelConfig.type = type;

      if (type === "look") {
        this.modelConfig.record = record;
        this.modelConfig.title = "查看密码";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "600px";
        this.modelConfig.show = true;
      } else {
        this.modelConfig.password = record;
        // this.modelConfig.record = record;
        this.modelConfig.title = "查看密码";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
        this.modelConfig.show = true;
      }
    },
    // async getPassword() {
    //   this.confirmLoading = true;
    //   let id = this.modelConfig.id;
    //   await sassLogicalResourceApi.getPassword(id).then((res) => {
    //     if (res.success) {
    //       this.confirmLoading = false;
    //       this.modelConfig.password = res.data;
    //     } else {
    //       this.confirmLoading = false;
    //       this.$message.error(res.errorMsg);
    //     }
    //   });
    // },
    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk(password) {
      this.modelConfig.show = false;
      if (this.modelConfig.type === "look") {
        this.openModel("password", password);
      }
      this.getData();
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.dateStyle {
  color: red;
}
.input_width {
  width: 240px;
}
.blank_contain {
  width: 340px;
}
.search_container {
  display: flex;
  justify-content: space-between;
  min-width: 1500px;
  overflow: auto;
}
.add_button {
  // margin-top: 20px;
  margin-bottom: 20px;
}
.popover_contain {
  height: 30px;
  width: 120px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.popover_contain:hover {
  background-color: #f3f3f3;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.modal_reset {
  color: rgb(136, 131, 131);
  font-size: 14px;
  padding-left: 36px;
  font-weight: 200;
}
/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
  /deep/.ant-table {
    min-height: 379px;
    }
</style>