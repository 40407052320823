import axios from "@/config/http";

export const sassLogicalResourceApi = {
  // 获取table数据
  getTableData(params) {
    return axios.get(
      "/cloud-platform-backend-facade/front-api/v1/tenant/logic/resource/infoByPage",
      {
        params,
      }
    );
  },
  // 获取密码
  getPassword(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/tenant/logic/resource/password/${params}`
    );
  },
  // 校验密码
  checkPassword(params) {
    return axios.get(
      "/cloud-platform-backend-facade/front-api/v1/tenant/logic/resource/password",
      {params}
    );
  },
};
